<template>
  <echarts-com v-loading="loading" id="integratedManagement-timeliness" :options="options"/>
</template>

<script>
import * as echarts from "echarts";
import {formatRatio} from "@/utils/util";

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom")
  },
  data() {
    return {
      options: {},
      loading: false,
      chart_data: [
        {
          attr: "thirtyMinutes",
          name: "30分钟以内",
          value: 0
        },
        {
          attr: "oneToFourHours",
          name: "1-4小时",
          value: 0
        },
        {
          attr: "fourToEighteenHours",
          name: "4-8小时",
          value: 0
        },
        {
          attr: "eightToTwelveHours",
          name: "8-12小时",
          value: 0
        },
        {
          attr: "twelveToTwentyFourHours",
          name: "12-24小时",
          value: 0
        },
        {
          attr: "twentyFourToFortyEightHours",
          name: "24-48小时",
          value: 0
        },
        {
          attr: "fortyEightHoursAbove",
          name: "48小时以上",
          value: 0
        }
      ]
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions(params = {}) {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/after/sales", params);
        if (res.code === 0) {
          for (let key in res.data) {
            this.chart_data.find((item) => {
              if (item.attr === key) {
                item.value = res.data[key]
              }
            })
          }

          let xAxis_data = this.chart_data.map(item => item.name);
          let values = this.chart_data.map(item => item.value);

          this.$set(this, "options", {
            tooltip: {
              trigger: "axis",
              extraCssText: "box-shadow: 0px 0px 13px rgba(0, 193, 153, 0.22);",
              padding: [15, 10],
            },
            xAxis: {
              type: "category",
              data: xAxis_data,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#2b4a6e",
                  type: "dashed",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            grid: {
              left: "0%",
              width: "100%",
              height: "90%",
              bottom: "0%",
              containLabel: true, // 包含坐标轴中的文字
            },
            series: [
              {
                type: "bar",
                barMaxWidth: 25,
                itemStyle: {
                  barBorderRadius: [33, 33, 0, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: "#00cec0"},
                    {offset: 1, color: "#9de5e0"},
                  ]),
                },
                data: values,
              },
            ],
          });
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("运维服务时效性分析获取失败");
        this.loading = false;
      }
    }
  }
}
</script>
